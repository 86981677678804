<template>
  <v-container class="content" v-if="user.id">
    <v-row no-gutters>
      <v-col align="center">
        <h1 class="mb-8">{{ user.firstName + ' ' + user.lastName + ' - ' + user.email }}</h1>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-btn color="red" @click="$router.push({name: 'retailerOrdersList'})" dark>
          Cancel
        </v-btn>
      </v-col>
      <v-col align="right">
        <v-btn color="light-green darken-2" @click="save" :loading="loading" dark>
          Save
          <v-icon right>mdi-content-save-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="3">
          <v-card-title>User Info</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                Id: {{ user.id }}
              </v-col>
              <v-col cols="4">
                Created: {{ new Date(user.createdDate).toLocaleString() }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field outlined v-model="user.firstName" label="First Name"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined v-model="user.lastName" label="Last Name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field outlined v-model="user.email" label="Email"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-btn v-if="!changePassword" @click="changePassword = true" color="primary">Change My Password</v-btn>
                <v-text-field v-else outlined v-model="user.password" label="Password"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="3">
          <v-card-title>Company</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                Id: {{ user.company.id }}
              </v-col>
              <v-col cols="4">
                Created: {{ new Date(user.company.createdDate).toLocaleString() }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                Name: {{ user.company.name }}
              </v-col>
              <v-col cols="4">
                ABC License #: {{ user.company.license }}
              </v-col>
              <v-col cols="4">
                Address: {{ user.company.address }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  name: "RetailerUserView",
  metaInfo: {
    title: "User View",
  },
  data() {
    return {
      user: {
        company: {}
      },
      loading: false,
      changePassword: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get("/api/retailer/user/").then((response) => {
        this.user = response.data;
      });
    },
    save() {
      this.loading = true;

      axios
          .put("/api/retailer/user/", this.user)
          .then(() => {
            this.$store.dispatch('banner/open', {text: 'User Updated!'})
            this.$router.push({name: 'retailerOrdersList'})
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>

<style scoped>
</style>
